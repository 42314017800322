import Header from "./components/layout/header";
import {Route, Routes, useNavigate} from "react-router";
import Home from "./views/home";
import Footer from "./components/layout/footer";
import P2pTrade from "./views/p2pTrade";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';


function App() {
    const router = useNavigate();

    useEffect(()=>{
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, behavior: "smooth" });
    },[router])


    return (
        <>
            <ToastContainer />
            <Header/>
            <Routes>
                <Route path="/" exact element={<Home/>}/>
                <Route path="/send_request" element={<P2pTrade/>}/>
            </Routes>
            <Footer/>

        </>
    );
}

export default App;

import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCoverflow, Pagination, Autoplay} from "swiper";
import { Link } from 'react-router-dom';


function Home() {
    return (
        <>
            {/*// <!-- start hero section -->*/}
            <section className="p-0 parallax mobile-height" data-parallax-background-ratio="0.5"
                     style={{backgroundImage: "url('/assets/images/banner.jpg')"}}>
                <div className="container position-relative">
                    <div className="row">
                        <div
                            className="col-12 col-lg-6 col-md-6 col-sm-8 full-screen md-h-650px sm-h-450px d-flex flex-column justify-content-center wow animate__fadeIn">
                            <span
                                className="text-extra-medium alt-font font-weight-500 text-uppercase text-yellow d-block margin-35px-bottom xs-margin-15px-bottom">buy cryptocurrencies on btc6x</span>
                            <h1 className="alt-font font-weight-700 text-white text-uppercase margin-2-half-rem-bottom letter-spacing-minus-2px sm-margin-20px-bottom xs-margin-15px-bottom">BUY
                                CRYPTO<br/>IN THE MOMENT!</h1>
                            <p className="text-extra-medium line-height-30px w-75 margin-3-rem-bottom lg-w-90 md-w-100 xs-margin-15px-bottom text-light">
                                Trade your desired crypto pair fast, easy, and secure only at BTC6X crypto exchange.
                            </p>
                            <a href="#call-request"
                               className="btn btn-fancy btn-large btn-yellow-ochre section-link align-self-start text-dark font-weight-700">order now</a>
                        </div>
                    </div>
                    <div className="down-section text-center z-index-1 xs-bottom-30px d-none d-sm-inline-block"><a
                        href="#about" className="section-link"><i
                        className="fas fa-arrow-down text-dark-charcoal bg-white box-shadow-extra-large w-45px h-45px line-height-46px border-radius-100"></i></a>
                    </div>
                </div>
            </section>
            {/*// <!-- end hero section -->*/}
            {/*// <!-- start section -->*/}
            <section className="p-0">
                <div className="container-fluid">
                    <div className="row row-cols-1 row-cols-xl-3 row-cols-md-3 row-cols-sm-2">
                        {/*// <!-- start fancy text box item -->*/}
                        <div className="col fancy-text-box-style-02 border-color-medium-gray p-0 wow animate__fadeIn">
                            <div className="text-box-wrapper align-items-center d-flex">
                                <div className="position-relative text-center w-100">
                                    <div className="text-box featureItem">
                                        {/* <i className="line-icon-Archery-2 icon-medium text-yellow olivine-green d-block margin-15px-bottom"></i> */}
                                        <img src="/assets/images/fast.png" alt="" />
                                        <div className="alt-font text-dark-charcoal font-weight-500 text-uppercase">Fast and Easy</div>
                                    </div>
                                    <div
                                        className="text-box-hover last-paragraph-no-margin bg-gradient-white-light-gray">
                                        <span
                                            className="alt-font text-dark-charcoal font-weight-500 line-height-normal text-uppercase d-block margin-10px-bottom">Fast and Easy</span>
                                        <p className="line-height-26px d-inline-block lg-w-70 md-w-100">
                                            In order to manage the requests, a simple system is put in place so that it can be used by all users
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*// <!-- end fancy text box item -->*/}
                        {/*// <!-- start fancy text box item -->*/}
                        <div className="col fancy-text-box-style-02 border-color-medium-gray p-0 wow animate__fadeIn"
                             data-wow-delay="0.1s">
                            <div className="text-box-wrapper align-items-center d-flex">
                                <div className="position-relative text-center w-100">
                                    <div className="text-box featureItem">
                                        {/* <i className="line-icon-Archery-2 icon-medium text-yellow olivine-green d-block margin-15px-bottom"></i> */}
                                        <img src="/assets/images/safety.png" alt="" />
                                        <div
                                            className="alt-font text-dark-charcoal font-weight-500 text-uppercase">Safety and Security</div>
                                    </div>
                                    <div
                                        className="text-box-hover last-paragraph-no-margin bg-gradient-white-light-gray">
                                        <span
                                            className="alt-font text-dark-charcoal font-weight-500 line-height-normal text-uppercase d-block margin-10px-bottom">Safety and Security</span>
                                        <p className="line-height-26px d-inline-block lg-w-70 md-w-100">
                                            The entire buying and selling processes will be carried out under the observation of the exchange.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*// <!-- end fancy text box item -->*/}
                        {/*// <!-- start fancy text box item -->*/}
                        <div className="col fancy-text-box-style-02 border-color-medium-gray p-0 wow animate__fadeIn"
                             data-wow-delay="0.2s">
                            <div className="text-box-wrapper align-items-center d-flex">
                                <div className="position-relative text-center w-100">
                                    <div className="text-box featureItem">
                                        {/* <i className="line-icon-Archery-2 icon-medium text-yellow olivine-green d-block margin-15px-bottom"></i> */}
                                        <img src="/assets/images/reasonable.png" alt="" />
                                        <div className="alt-font text-dark-charcoal font-weight-500 text-uppercase">Reasonable Prices</div>
                                    </div>
                                    <div
                                        className="text-box-hover last-paragraph-no-margin bg-gradient-white-light-gray">
                                        <span
                                            className="alt-font text-dark-charcoal font-weight-500 line-height-normal text-uppercase d-block margin-10px-bottom">Reasonable Prices</span>
                                        <p className="line-height-26px d-inline-block lg-w-70 md-w-100">
                                            users will compete with each other to offer the most reasonable prices for buying and selling.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- end fancy text box item -->*/}
                        {/*<!-- start fancy text box item -->*/}
                        {/* <div className="col fancy-text-box-style-02 border-color-medium-gray p-0 wow animate__fadeIn"
                             data-wow-delay="0.3s">
                            <div className="text-box-wrapper align-items-center d-flex">
                                <div className="position-relative text-center w-100">
                                    <div className="text-box">
                                        <i className="line-icon-Archery-2 icon-medium text-yellow olivine-green d-block margin-15px-bottom"></i>
                                        <span className="alt-font text-dark-charcoal font-weight-500 text-uppercase">best price</span>
                                    </div>
                                    <div
                                        className="text-box-hover last-paragraph-no-margin bg-gradient-white-light-gray">
                                        <span
                                            className="alt-font text-dark-charcoal font-weight-500 line-height-normal text-uppercase d-block margin-10px-bottom">best price</span>
                                        <p className="line-height-26px d-inline-block lg-w-70 md-w-100">Lorem ipsum
                                            dolor consectetur adipiscing eiusmod tempor</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/*<!-- end fancy text box item -->*/}
                        {/*<!-- start fancy text box item -->*/}
                        {/* <div className="col fancy-text-box-style-02 border-color-medium-gray p-0 wow animate__fadeIn"
                             data-wow-delay="0.4s">
                            <div className="text-box-wrapper align-items-center d-flex">
                                <div className="position-relative text-center w-100">
                                    <div className="text-box">
                                        <i className="line-icon-Archery-2 icon-medium text-yellow olivine-green d-block margin-15px-bottom"></i>
                                        <span className="alt-font text-dark-charcoal font-weight-500 text-uppercase">all crypto</span>
                                    </div>
                                    <div
                                        className="text-box-hover last-paragraph-no-margin bg-gradient-white-light-gray">
                                        <span
                                            className="alt-font text-dark-charcoal font-weight-500 line-height-normal text-uppercase d-block margin-10px-bottom">all crypto</span>
                                        <p className="line-height-26px d-inline-block lg-w-70 md-w-100">Lorem ipsum
                                            dolor consectetur adipiscing eiusmod tempor</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/*<!-- end fancy text box item -->*/}
                        {/*<!-- start fancy text box item -->*/}
                        {/* <div className="col fancy-text-box-style-02 border-color-medium-gray p-0 wow animate__fadeIn"
                             data-wow-delay="0.5s">
                            <div className="text-box-wrapper align-items-center d-flex">
                                <div className="position-relative text-center w-100">
                                    <div className="text-box">
                                        <i className="line-icon-Archery-2 icon-medium text-yellow olivine-green d-block margin-15px-bottom"></i>
                                        <span className="alt-font text-dark-charcoal font-weight-500 text-uppercase">fast transfer</span>
                                    </div>
                                    <div
                                        className="text-box-hover last-paragraph-no-margin bg-gradient-white-light-gray">
                                        <span
                                            className="alt-font text-dark-charcoal font-weight-500 line-height-normal text-uppercase d-block margin-10px-bottom">fast transfer</span>
                                        <p className="line-height-26px d-inline-block lg-w-70 md-w-100">Lorem ipsum
                                            dolor consectetur adipiscing eiusmod tempor</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/*<!-- end fancy text box item -->*/}
                    </div>
                </div>
            </section>
            {/*<!-- end section -->*/}
            {/*<!-- start section -->*/}
            <section id="about" className="big-section wow animate__fadeIn">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div
                            className="col-12 col-lg-4 col-md-8 text-center text-lg-start md-margin-6-rem-bottom wow animate__fadeIn">
                            <span
                                className="alt-font text-medium text-yellow text-uppercase font-weight-500 d-inline-block">SERVICES</span>
                            <h5 className="alt-font text-dark-charcoal font-weight-700 text-uppercase letter-spacing-minus-1px w-85 lg-w-90 md-w-100">OUR SERVICES
                            </h5>
                            <p className="w-80 margin-35px-bottom lg-w-100">
                                With BTC6X you will get an unparalleled experience of trading digital currencies in a secure and user-friendly environment with powerful market analysis to meet all your crypto exchange demands.
                            </p>
                            {/*<!-- <a href="index.html" className="btn btn-fancy btn-small btn-transparent-light-gray">Discover litho</a> -->*/}
                        </div>
                        {/*<!-- start services item -->*/}
                        <div className="col-12 col-lg-4 col-md-6 sm-margin-30px-bottom wow animate__fadeIn"
                             data-wow-delay="0.2s">
                            <div className="services-box-style-01 bg-extra-dark-gray box-shadow-large">
                                <div className="imagex-box position-relative overflow-hidden">
                                    <img src="/assets/images/direct buy.jpg" alt=""/>
                                    <div
                                        className="services-box-hover align-items-center justify-content-center d-flex">
                                        <div className="services-icon">
                                            <Link to="/send_request" className="rounded-circle bg-white"><i
                                                className="fas fa-arrow-right text-dark-charcoal"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="position-relative bg-white last-paragraph-no-margin padding-3-rem-tb padding-3-half-rem-lr">
                                    <span
                                        className="alt-font font-weight-500 text-dark-charcoal text-uppercase d-block margin-5px-bottom">buy and sell directly</span>
                                    <p>BTC6X provides an extensive array of crypto pairs for smooth, straightforward, fast, and easy purchase or sale of cryptocurrencies.</p>
                                    <div
                                        className="w-100 h-4px bg-yellow position-absolute left-0px bottom-0px d-block"></div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- end services item -->*/}
                        {/*<!-- start services item -->*/}
                        <div className="col-12 col-lg-4 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <div className="services-box-style-01 bg-extra-dark-gray  box-shadow-large">
                                <div className="imagex-box position-relative overflow-hidden">
                                    <img src="/assets/images/p2p.jpg" alt=""/>
                                    <div
                                        className="services-box-hover align-items-center justify-content-center d-flex">
                                        <div className="services-icon">
                                            <a href="#" className="rounded-circle bg-white"><i
                                                className="fas fa-arrow-right text-dark-charcoal"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="position-relative bg-white last-paragraph-no-margin padding-3-rem-tb padding-3-half-rem-lr">
                                    <span
                                        className="alt-font font-weight-500 text-dark-charcoal text-uppercase d-block margin-5px-bottom">p2p (coming soon)</span>
                                    <p>To provide our users with a higher level of ease in access to faster trading of digital assets, BTC6X will soon offer P2P trading services.</p>
                                    <div
                                        className="w-100 h-4px bg-yellow position-absolute left-0px bottom-0px d-block"></div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- end services item -->*/}
                    </div>
                </div>
            </section>
            {/*<!-- end section -->*/}
            {/*<!-- start section -->*/}
            <section className="bg-gradient-white-light-gray border-top border-width-1px border-color-medium-gray"  style={{display : "none"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-md-7 text-center margin-4-rem-bottom sm-margin-3-rem-bottom wow animate__fadeIn">
                            <span
                                className="alt-font text-medium text-yellow text-uppercase font-weight-500 d-block margin-15px-bottom sm-margin-10px-bottom">our partner companies</span>
                            <h5 className="alt-font text-dark-charcoal font-weight-700 text-uppercase letter-spacing-minus-1px">Clients
                                testimonials</h5>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12 text-center">
                            <Swiper
                                className={"swiper-container black-move swiper-horizontal-3d padding-7-rem-tb swiper-pagination-bottom sm-padding-10-rem-top xs-padding-8-rem-top"}
                                loop={true}
                                centeredSlides={true}
                                effect={"coverflow"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 100,
                                    depth: 150,
                                    modifier: 1.5,
                                    slideShadows: true
                                }}
                                navigation={{"nextEl": ".swiper-button-next", "prevEl": ".swiper-button-prev"}}
                                autoplay={{"delay": 3000, "disableOnInteraction": false}}
                                pagination={{"el": ".swiper-pagination", "clickable": true, "dynamicBullets": true}}
                                breakpoints={{"768": {"slidesPerView": 2}}}
                                slidesPerView={1}
                                // onSlideChange={() => console.log('slide change')}
                                // onSwiper={(swiper) => console.log(swiper)}
                                modules={[EffectCoverflow, Pagination, Autoplay]}

                            >
                                <SwiperSlide className={"swiper-slide bg-white border-radius-4px"}>
                                    <div
                                        className="position-relative padding-5-rem-lr  md-padding-3-rem-lr padding-4-half-rem-bottom padding-8-rem-top sm-padding-10-rem-top xs-padding-8-rem-top">
                                        <img alt="" src="https://via.placeholder.com/148x148"
                                             className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border-all border-color-white box-shadow-extra-large border-width-8px"/>
                                        <div className="testimonials-content">
                                            <span
                                                className="text-extra-medium text-extra-dark-gray margin-10px-bottom d-block font-weight-500">Just love their design for all stunning details!</span>
                                            <p className="margin-30px-bottom">Lorem ipsum dolor sit consectetur
                                                adipiscing do eiusmod tempor incididunt ut labore et dolore magna ut
                                                enim ad minim veniam nostrud exercitation ullamco laboris nisi.</p>
                                        </div>
                                        <div
                                            className="testimonials-author text-medium margin-5px-bottom text-yellow text-uppercase font-weight-500 d-inline-block">Alexander
                                            Harvard
                                        </div>
                                        <div className="testimonials-position text-medium">ThemeZaa Design</div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className={"swiper-slide bg-white border-radius-4px"}>
                                    <div
                                        className="position-relative padding-5-rem-lr md-padding-3-rem-lr padding-4-half-rem-bottom padding-8-rem-top sm-padding-10-rem-top xs-padding-8-rem-top">
                                        <img alt="" src="https://via.placeholder.com/148x148"
                                             className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border-all border-color-white box-shadow-extra-large border-width-8px"/>
                                        <div className="testimonials-content">
                                            <span
                                                className="text-extra-medium text-extra-dark-gray margin-10px-bottom d-block font-weight-500">Every element is designed beautifully and perfect!</span>
                                            <p className="margin-30px-bottom">Lorem ipsum dolor sit consectetur
                                                adipiscing do eiusmod tempor incididunt ut labore et dolore magna ut
                                                enim ad minim veniam nostrud exercitation ullamco laboris nisi.</p>
                                        </div>
                                        <div
                                            className="testimonials-author text-medium margin-5px-bottom text-yellow text-uppercase font-weight-500 d-inline-block">Shoko
                                            Mugikura
                                        </div>
                                        <div className="testimonials-position text-medium">Google Design</div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className={"swiper-slide bg-white border-radius-4px"}>
                                    <div
                                        className="position-relative padding-5-rem-lr  md-padding-3-rem-lr padding-4-half-rem-bottom padding-8-rem-top sm-padding-10-rem-top xs-padding-8-rem-top">
                                        <img alt="" src="https://via.placeholder.com/148x148"
                                             className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border-all border-color-white box-shadow-extra-large border-width-8px"/>
                                        <div className="testimonials-content">
                                            <span
                                                className="text-extra-medium text-extra-dark-gray margin-10px-bottom d-block font-weight-500">Simple and easy to integrate and build the website!</span>
                                            <p className="margin-30px-bottom">Lorem ipsum dolor sit consectetur
                                                adipiscing do eiusmod tempor incididunt ut labore et dolore magna ut
                                                enim ad minim veniam nostrud exercitation ullamco laboris nisi.</p>
                                        </div>
                                        <div
                                            className="testimonials-author text-medium margin-5px-bottom text-yellow text-uppercase font-weight-500 d-inline-block">Herman
                                            Miller
                                        </div>
                                        <div className="testimonials-position text-medium">Apple Design</div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                            {/*<div className="swiper-container black-move swiper-horizontal-3d padding-7-rem-tb swiper-pagination-bottom sm-padding-10-rem-top xs-padding-8-rem-top"  data-slider-options='{ "loop": true, "slidesPerView": 1,"centeredSlides":true,"effect":"coverflow","coverflowEffect":{"rotate":0,"stretch":100,"depth":150,"modifier":1.5,"slideShadows":true}, "navigation": { "nextEl": ".swiper-button-next", "prevEl": ".swiper-button-prev" }, "autoplay": { "delay": 3000, "disableOnInteraction": false }, "pagination": { "el": ".swiper-pagination", "clickable": true, "dynamicBullets": true }, "breakpoints": { "768": { "slidesPerView": 2 } } }'>
                                <div className="swiper-wrapper">
                                    // <!-- start slider item -->
                                    <div className="swiper-slide bg-white border-radius-4px">
                                        <div className="position-relative padding-5-rem-lr  md-padding-3-rem-lr padding-4-half-rem-bottom padding-8-rem-top sm-padding-10-rem-top xs-padding-8-rem-top">
                                            <img alt="" src="https://via.placeholder.com/148x148" className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border-all border-color-white box-shadow-extra-large border-width-8px" />
                                                <div className="testimonials-content">
                                                    <span className="text-extra-medium text-extra-dark-gray margin-10px-bottom d-block font-weight-500">Just love their design for all stunning details!</span>
                                                    <p className="margin-30px-bottom">Lorem ipsum dolor sit consectetur adipiscing do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi.</p>
                                                </div>
                                                <div className="testimonials-author text-medium margin-5px-bottom text-yellow text-uppercase font-weight-500 d-inline-block">Alexander Harvard</div>
                                                <div className="testimonials-position text-medium">ThemeZaa Design</div>
                                        </div>
                                    </div>
                                    // <!-- end slider item -->
                                    // <!-- start slider item -->
                                    <div className="swiper-slide bg-white border-radius-4px">
                                        <div className="position-relative padding-5-rem-lr md-padding-3-rem-lr padding-4-half-rem-bottom padding-8-rem-top sm-padding-10-rem-top xs-padding-8-rem-top">
                                            <img alt="" src="https://via.placeholder.com/148x148" className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border-all border-color-white box-shadow-extra-large border-width-8px"/>
                                                <div className="testimonials-content">
                                                    <span className="text-extra-medium text-extra-dark-gray margin-10px-bottom d-block font-weight-500">Every element is designed beautifully and perfect!</span>
                                                    <p className="margin-30px-bottom">Lorem ipsum dolor sit consectetur adipiscing do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi.</p>
                                                </div>
                                                <div className="testimonials-author text-medium margin-5px-bottom text-yellow text-uppercase font-weight-500 d-inline-block">Shoko Mugikura</div>
                                                <div className="testimonials-position text-medium">Google Design</div>
                                        </div>
                                    </div>
                                    // <!-- end slider item -->
                                    // <!-- start slider item -->
                                    <div className="swiper-slide bg-white border-radius-4px">
                                        <div className="position-relative padding-5-rem-lr  md-padding-3-rem-lr padding-4-half-rem-bottom padding-8-rem-top sm-padding-10-rem-top xs-padding-8-rem-top">
                                            <img alt="" src="https://via.placeholder.com/148x148" className="absolute-middle-center top-0px rounded-circle w-150px xs-w-100px border-all border-color-white box-shadow-extra-large border-width-8px" />
                                                <div className="testimonials-content">
                                                    <span className="text-extra-medium text-extra-dark-gray margin-10px-bottom d-block font-weight-500">Simple and easy to integrate and build the website!</span>
                                                    <p className="margin-30px-bottom">Lorem ipsum dolor sit consectetur adipiscing do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi.</p>
                                                </div>
                                                <div className="testimonials-author text-medium margin-5px-bottom text-yellow text-uppercase font-weight-500 d-inline-block">Herman Miller</div>
                                                <div className="testimonials-position text-medium">Apple Design</div>
                                        </div>
                                    </div>
                                    // <!-- end slider item -->
                                </div>
                                // <!-- start slider pagination -->
                                // <!-- <div className="swiper-pagination"></div> -->
                                // <!-- end slider pagination -->
                            </div>*/}
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- end section -->*/}
            {/*<!-- start section -->*/}
            <section className="big-section wow animate__fadeIn">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center margin-seven-bottom">
                            <h4 className="alt-font text-extra-dark-gray font-weight-700">AVAILABLE CRYPTOS</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 tab-style-06 wow animate__fadeIn" data-wow-delay="0.2s">
                            {/*// <!-- start tab navigation -->*/}
                            <ul className="nav nav-tabs text-center justify-content-center">
                                <li className="nav-item"><a className="alt-font nav-link text-uppercase active"
                                                            data-bs-toggle="tab" href="#buy">buy crypto</a></li>
                                <li className="nav-item"><a className="alt-font nav-link text-uppercase"
                                                            data-bs-toggle="tab" href="#sell">sell crypto</a></li>
                            </ul>
                            {/*// <!-- end tab navigation -->*/}
                            <div className="tab-content">
                                {/*// <!-- start tab content -->*/}
                                <div className="tab-pane med-text fade in active show" id="buy">
                                    <div className="panel-group time-table">
                                        <div className="panel border-color-black-transparent">
                                            <div className="panel-heading">
                                                <span className="panel-time title-extra-small">CCU</span>
                                                <div className="panel-body feature-box feature-box-left-icon-middle">
                                                    <div
                                                        className="feature-box-icon margin-30px-right md-margin-5px-right">
                                                        <img
                                                            className="rounded-circle w-110px h-110px border-all border-width-6px border-color-white box-shadow-large margin-15px-right md-w-70px md-h-70px"
                                                            src="/assets/images/ccu.jpg" alt=""/>
                                                    </div>
                                                    <div className="feature-box-content last-paragraph-no-margin">
                                                        <div className="panel-title">
                                                            <span
                                                                className="text-extra-dark-gray d-inline-block font-weight-500 margin-5px-bottom">counos coin</span>
                                                        </div>
                                                        <p className="w-65 lg-w-85 md-w-90 xs-w-100">
                                                            Can be registered on Conous H network
                                                        </p>
                                                    </div>
                                                </div>
                                                <a className="panel-speaker"><i
                                                    className="feather icon-feather-arrow-right text-yellow margin-10px-right"></i>submit
                                                    purchase</a>
                                            </div>
                                        </div>
                                        <div className="panel border-color-black-transparent">
                                            <div className="panel-heading">
                                                <span className="panel-time title-extra-small">USDT</span>
                                                <div className="panel-body feature-box feature-box-left-icon-middle">
                                                    <div
                                                        className="feature-box-icon margin-30px-right md-margin-5px-right">
                                                        <img
                                                            className="rounded-circle w-110px h-110px border-all border-width-6px border-color-white box-shadow-large margin-15px-right md-w-70px md-h-70px"
                                                            src="/assets/images/usdt.jpg" alt=""/>
                                                    </div>
                                                    <div className="feature-box-content last-paragraph-no-margin">
                                                        <div className="panel-title">
                                                            <span
                                                                className="text-extra-dark-gray d-inline-block font-weight-500 margin-5px-bottom">theter</span>
                                                        </div>
                                                        <p className="w-65 lg-w-85 md-w-90 xs-w-100">
                                                        Can be registered on Tron & Binance network
                                                        </p>
                                                    </div>
                                                </div>
                                                <a className="panel-speaker"><i
                                                    className="feather icon-feather-arrow-right text-yellow margin-10px-right"></i>submit
                                                    purchase</a>
                                            </div>
                                        </div>
                                        <div className="panel border-color-transparent">
                                            <div className="panel-heading">
                                                <span className="panel-time title-extra-small">ETH</span>
                                                <div className="panel-body feature-box feature-box-left-icon-middle">
                                                    <div
                                                        className="feature-box-icon margin-30px-right md-margin-5px-right">
                                                        <img
                                                            className="rounded-circle w-110px h-110px border-all border-width-6px border-color-white box-shadow-large margin-15px-right md-w-70px md-h-70px"
                                                            src="/assets/images/eth.jpg" alt=""/>
                                                    </div>
                                                    <div className="feature-box-content last-paragraph-no-margin">
                                                        <div className="panel-title">
                                                            <span
                                                                className="text-extra-dark-gray d-inline-block font-weight-500 margin-5px-bottom">Etherium</span>
                                                        </div>
                                                        <p className="w-65 lg-w-85 md-w-90 xs-w-100">
                                                            Can be registered on Etherium & Binance network . Minimum purchase is $200
                                                        </p>
                                                    </div>
                                                </div>
                                                <a className="panel-speaker"><i
                                                    className="feather icon-feather-arrow-right text-yellow margin-10px-right"></i>submit
                                                    purchase</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*// <!-- end tab content -->*/}
                                {/*// <!-- start tab content -->*/}
                                <div className="tab-pane med-text fade in" id="sell">
                                    <div className="panel-group time-table">
                                        <div className="panel border-color-black-transparent">
                                            <div className="panel-heading">
                                                <span className="panel-time title-extra-small">CCU</span>
                                                <div className="panel-body feature-box feature-box-left-icon-middle">
                                                    <div
                                                        className="feature-box-icon margin-30px-right md-margin-5px-right">
                                                        <img
                                                            className="rounded-circle w-110px h-110px border-all border-width-6px border-color-white box-shadow-large margin-15px-right md-w-70px md-h-70px"
                                                            src="/assets/images/ccu.jpg" alt=""/>
                                                    </div>
                                                    <div className="feature-box-content last-paragraph-no-margin">
                                                        <div className="panel-title">
                                                            <span
                                                                className="text-extra-dark-gray d-inline-block font-weight-500 margin-5px-bottom">counos coin</span>
                                                        </div>
                                                        <p className="w-65 lg-w-85 md-w-90 xs-w-100">
                                                            Can be registered on Conous H network
                                                        </p>
                                                    </div>
                                                </div>
                                                <a className="panel-speaker"><i
                                                    className="feather icon-feather-arrow-right text-yellow margin-10px-right"></i>submit
                                                    purchase</a>
                                            </div>
                                        </div>
                                        <div className="panel border-color-black-transparent">
                                            <div className="panel-heading">
                                                <span className="panel-time title-extra-small">USDT</span>
                                                <div className="panel-body feature-box feature-box-left-icon-middle">
                                                    <div
                                                        className="feature-box-icon margin-30px-right md-margin-5px-right">
                                                        <img
                                                            className="rounded-circle w-110px h-110px border-all border-width-6px border-color-white box-shadow-large margin-15px-right md-w-70px md-h-70px"
                                                            src="/assets/images/usdt.jpg" alt=""/>
                                                    </div>
                                                    <div className="feature-box-content last-paragraph-no-margin">
                                                        <div className="panel-title">
                                                            <span
                                                                className="text-extra-dark-gray d-inline-block font-weight-500 margin-5px-bottom">theter</span>
                                                        </div>
                                                        <p className="w-65 lg-w-85 md-w-90 xs-w-100">
                                                            Can be registered on Tron & Binance network
                                                        </p>
                                                    </div>
                                                </div>
                                                <a className="panel-speaker"><i
                                                    className="feather icon-feather-arrow-right text-yellow margin-10px-right"></i>submit
                                                    purchase</a>
                                            </div>
                                        </div>
                                        <div className="panel border-color-transparent">
                                            <div className="panel-heading">
                                                <span className="panel-time title-extra-small">ETH</span>
                                                <div className="panel-body feature-box feature-box-left-icon-middle">
                                                    <div
                                                        className="feature-box-icon margin-30px-right md-margin-5px-right">
                                                        <img
                                                            className="rounded-circle w-110px h-110px border-all border-width-6px border-color-white box-shadow-large margin-15px-right md-w-70px md-h-70px"
                                                            src="/assets/images/eth.jpg" alt=""/>
                                                    </div>
                                                    <div className="feature-box-content last-paragraph-no-margin">
                                                        <div className="panel-title">
                                                            <span
                                                                className="text-extra-dark-gray d-inline-block font-weight-500 margin-5px-bottom">Etherium</span>
                                                        </div>
                                                        <p className="w-65 lg-w-85 md-w-90 xs-w-100">
                                                            Can be registered on Etherium & Binance network . Minimum purchase is $200
                                                        </p>
                                                    </div>
                                                </div>
                                                <a className="panel-speaker"><i
                                                    className="feather icon-feather-arrow-right text-yellow margin-10px-right"></i>submit
                                                    purchase</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*// <!-- end tab content -->*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- end section -->*/}
            {/*<!-- start section -->*/}
            <section id="call-request"
                     className="contain-background wow animate__fadeIn md-background-position-right xs-no-padding-tb xs-border-tb border-color-medium-gray"
                     style={{background: "url('/assets/images/bottom-banner.jpg') #F7F7F7"}}>
                <div className="container xs-no-padding-lr">
                    <div className="row justify-content-end">
                        <div className="col-12 col-xl-7 col-lg-8 col-md-10 wow animate__fadeIn" data-wow-delay="0.3s">
                            <div
                                className="text-center bg-white box-shadow-large border-radius-6px padding-5-rem-tb padding-7-rem-lr sm-padding-5-rem-all xs-padding-3-half-rem-lr xs-padding-6-rem-tb xs-no-border-radius">
                                <span
                                    className="alt-font text-medium text-yellow text-uppercase font-weight-500 d-block margin-15px-bottom sm-margin-10px-bottom">Request to buy</span>
                                <h5 className="alt-font text-dark-charcoal font-weight-700 text-uppercase letter-spacing-minus-1px margin-40px-bottom w-75 mx-auto xs-w-100">REGISTER YOUR PURCHASE NOW</h5>
                                {/*<!-- start contact form -->*/}
                                <form className="margin-30px-bottom">
                                    {/* <input className="medium-input border-radius-5px margin-25px-bottom required"
                                           type="text" name="name" placeholder="Your name"/>
                                    <input className="medium-input border-radius-5px margin-25px-bottom required"
                                           type="email" name="email" placeholder="Your email address"/>
                                    <input type="hidden" name="redirect" value=""/> */}
                                    <Link to={"/send_request"}>
                                        <button
                                            className="btn btn-fancy btn-large btn-yellow-ochre btn-round-edge w-100 no-margin-bottom submit"
                                            name="submit">ORDER NOW
                                        </button>
                                    </Link>
                                    <div className="form-results border-radius-5px d-none"></div>
                                </form>
                                {/* <p className="w-80 mx-auto text-extra-small line-height-22px m-0 xs-w-100">We are
                                    committed to protecting your privacy. We will never collect information about you
                                    without your explicit consent.</p> */}
                                {/*<!-- end contact form -->*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- end section -->*/}
        </>
    );
}

export default Home;
import React from 'react';
import $ from "jquery";
import i18next from "i18next";
import { Link } from 'react-router-dom';


function Header() {
    const selected = localStorage.getItem("i18nextLng") || "en";
    const [menuAnchor, setMenuAnchor] = React.useState(null);

    const languageMap = {
        en: { label: "English", dir: "ltr", active: true },
        ar: { label: "فارسی", dir: "rtl", active: false },
    };

    React.useEffect(() => {

        if(languageMap[selected].dir === "rtl"){
            var head  = document.getElementsByTagName('head')[0];
            var link  = document.createElement('link');
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = '/assets/css/style-rtl.css';
            link.media = 'all';
            head.appendChild(link);
        }else{
            for (let i = 0; i < $('link').length; i++) {
                const linkItem = $('link').eq(i);
                if($(linkItem).attr("href").search("rtl") > -1){
                    $(linkItem).remove();
                    break
                }
            }
        }
        // document.body.dir = languageMap[selected].dir;
    }, [menuAnchor, selected]);
    return (
        <>
            <header>
                {/*<!-- start navigation -->*/}
                <nav
                    className="navbar navbar-expand-lg navbar-light bg-transparent border-bottom border-color-black-transparent header-light fixed-top navbar-boxed header-reverse-scroll">
                    <div className="container-fluid nav-header-container">
                        <div className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                            <Link className="navbar-brand" to="/">
                                <img src="/assets/images/logoSite.png"
                                     data-at2x="/assets/images/logoSite.png"
                                     className="default-logo" alt=""/>
                                <img src="/assets/images/logoSiteBlack.png"
                                     data-at2x="/assets/images/logoSiteBlack.png"
                                     className="alt-logo" alt=""/>
                                <img src="/assets/images/logoSiteBlack.png"
                                     data-at2x="/assets/images/logoSiteBlack.png" className="mobile-logo" alt=""/>
                            </Link>
                        </div>
                        <div className="col-auto col-lg-8 menu-order px-lg-0">
                            <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav" aria-controls="navbarNav"
                                    aria-label="Toggle navigation">
                                <span className="navbar-toggler-line"></span>
                                <span className="navbar-toggler-line"></span>
                                <span className="navbar-toggler-line"></span>
                                <span className="navbar-toggler-line"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                                <ul className="navbar-nav alt-font">
                                    <li className="nav-item dropdown megamenu">
                                        <Link to="/" className="nav-link text-uppercase">Home</Link>
                                        {/* <i className="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown"
                                           aria-hidden="true"></i>
                                        <div className="menu-back-div dropdown-menu megamenu-content" role="menu">
                                            <div className="d-lg-flex justify-content-center">
                                                <ul className="d-lg-inline-block">
                                                    <li className="dropdown-header">Corporate</li>
                                                    <li><a href="home-startup.html">Startup</a></li>
                                                    <li><a href="home-business.html">Business</a></li>
                                                    <li><a href="home-corporate.html">Corporate</a></li>
                                                    <li><a href="home-finance.html">Finance</a></li>
                                                    <li><a href="home-application.html">Application</a></li>
                                                    <li><a href="home-consulting.html">Consulting</a></li>
                                                    <li><a href="home-digital-agency.html">Digital agency</a></li>
                                                    <li><a href="home-seo-agency.html">SEO agency</a></li>
                                                    <li><a href="home-events-conference.html">Events & conference</a>
                                                    </li>
                                                    <li><a href="home-marketing-agency.html">Marketing agency</a></li>
                                                </ul>
                                                <ul className="d-lg-inline-block">
                                                    <li className="dropdown-header">Specialized</li>
                                                    <li><a href="home-restaurant.html">Restaurant</a></li>
                                                    <li><a href="home-architecture.html">Architecture</a></li>
                                                    <li><a href="home-hotel-resort.html">Hotel & resort</a></li>
                                                    <li><a href="home-travel-agency.html">Travel agency</a></li>
                                                    <li><a href="home-yoga-meditation.html">Yoga & meditation</a></li>
                                                    <li><a href="home-gym-fitness.html">Gym & fitness</a></li>
                                                    <li><a href="home-spa-salon.html">Spa salon</a></li>
                                                    <li><a href="home-cafe.html">Cafe</a></li>
                                                    <li><a href="home-decor.html">Home decor</a></li>
                                                    <li><a href="home-dentist.html">Dentist</a></li>
                                                    <li><a href="home-interior-design.html">Interior design</a></li>
                                                </ul>
                                                <ul className="d-lg-inline-block">
                                                    <li className="dropdown-header">Portfolio</li>
                                                    <li><a href="home-design-agency.html">Design agency</a></li>
                                                    <li><a href="home-web-agency.html">Web agency</a></li>
                                                    <li><a href="home-creative-agency.html">Creative agency</a></li>
                                                    <li><a href="home-freelancer.html">Freelancer</a></li>
                                                    <li><a href="home-branding-agency.html">Branding agency</a></li>
                                                    <li><a href="home-photography.html">Photography</a></li>
                                                    <li><a href="home-personal-portfolio.html">Personal portfolio</a>
                                                    </li>
                                                    <li><a href="home-vertical-portfolio.html">Vertical portfolio</a>
                                                    </li>
                                                    <li><a href="home-interactive-portfolio.html">Interactive
                                                        portfolio</a></li>
                                                    <li><a href="home-split-portfolio.html">Split portfolio</a></li>
                                                    <li><a href="home-creative-portfolio.html">Creative portfolio</a>
                                                    </li>
                                                </ul>
                                                <ul className="d-lg-inline-block">
                                                    <li className="dropdown-header">Other</li>
                                                    <li><a href="home-furniture-shop.html">Furniture shop</a></li>
                                                    <li><a href="home-fashion-shop.html">Fashion shop</a></li>
                                                    <li><a href="home-magazine.html">Magazine</a></li>
                                                    <li><a href="home-lifestyle-blog.html">Lifestyle blog</a></li>
                                                    <li><a href="home-classic-blog.html">Classic blog</a></li>
                                                    <li><a href="home-blog-metro.html">Blog metro</a></li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </li>
                                    <li className="nav-item dropdown simple-dropdown">
                                        <Link to="/send_request" className="nav-link text-uppercase">Send request</Link>
                                        {/* <i className="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown"
                                           aria-hidden="true"></i>
                                        <ul className="dropdown-menu" role="menu">
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">About<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="about-me.html">About me</a></li>
                                                    <li><a href="about-us.html">About us</a></li>
                                                    <li><a href="our-story.html">Our story</a></li>
                                                    <li><a href="who-we-are.html">Who we are</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Services<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="our-services.html">Our services</a></li>
                                                    <li><a href="what-we-offers.html">What we offer</a></li>
                                                    <li><a href="our-process.html">Our process</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Contact<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="contact-us-simple.html">Contact simple</a></li>
                                                    <li><a href="contact-us-classic.html">Contact classic</a></li>
                                                    <li><a href="contact-us-modern.html">Contact modern</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Additional pages<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="our-team.html">Our team</a></li>
                                                    <li><a href="latest-news.html">Latest news</a></li>
                                                    <li><a href="pricing-packages.html">Pricing packages</a></li>
                                                    <li><a href="404.html">Error 404</a></li>
                                                    <li><a href="maintenance.html">Maintenance</a></li>
                                                    <li><a href="coming-soon.html">Coming soon</a></li>
                                                    <li><a href="coming-soon-v2.html">Coming soon - V2</a></li>
                                                    <li><a href="faq.html">FAQ's</a></li>
                                                    <li><a href="search-result.html">Search result</a></li>
                                                </ul>
                                            </li>
                                        </ul> */}
                                    </li>
                                    <li className="nav-item dropdown simple-dropdown">
                                        <a href="http://btc6x.com/" target="_blank" className="nav-link text-uppercase">Exchange</a>
                                        {/* <i className="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown"
                                           aria-hidden="true"></i>
                                        <ul className="dropdown-menu" role="menu">
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">About<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="about-me.html">About me</a></li>
                                                    <li><a href="about-us.html">About us</a></li>
                                                    <li><a href="our-story.html">Our story</a></li>
                                                    <li><a href="who-we-are.html">Who we are</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Services<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="our-services.html">Our services</a></li>
                                                    <li><a href="what-we-offers.html">What we offer</a></li>
                                                    <li><a href="our-process.html">Our process</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Contact<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="contact-us-simple.html">Contact simple</a></li>
                                                    <li><a href="contact-us-classic.html">Contact classic</a></li>
                                                    <li><a href="contact-us-modern.html">Contact modern</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Additional pages<i
                                                    className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="our-team.html">Our team</a></li>
                                                    <li><a href="latest-news.html">Latest news</a></li>
                                                    <li><a href="pricing-packages.html">Pricing packages</a></li>
                                                    <li><a href="404.html">Error 404</a></li>
                                                    <li><a href="maintenance.html">Maintenance</a></li>
                                                    <li><a href="coming-soon.html">Coming soon</a></li>
                                                    <li><a href="coming-soon-v2.html">Coming soon - V2</a></li>
                                                    <li><a href="faq.html">FAQ's</a></li>
                                                    <li><a href="search-result.html">Search result</a></li>
                                                </ul>
                                            </li>
                                        </ul> */}
                                    </li>
                                    {/* <li className="nav-item dropdown simple-dropdown">
                                        <a href="#" className="nav-link text-uppercase">Fourm</a>
                                        <i className="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown"
                                           aria-hidden="true"></i>
                                        <ul className="dropdown-menu" role="menu">
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio
                                                    classic<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-classic-two-column.html">Classic 2 column</a>
                                                    </li>
                                                    <li><a href="portfolio-classic-three-column.html">Classic 3
                                                        column</a></li>
                                                    <li><a href="portfolio-classic-four-column.html">Classic 4
                                                        column</a></li>
                                                    <li><a href="portfolio-classic-masonry.html">Classic masonry</a>
                                                    </li>
                                                    <li><a href="portfolio-classic-metro.html">Classic metro</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio
                                                    boxed<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-boxed-two-column.html">Boxed 2 column</a>
                                                    </li>
                                                    <li><a href="portfolio-boxed-three-column.html">Boxed 3 column</a>
                                                    </li>
                                                    <li><a href="portfolio-boxed-four-column.html">Boxed 4 column</a>
                                                    </li>
                                                    <li><a href="portfolio-boxed-masonry.html">Boxed masonry</a></li>
                                                    <li><a href="portfolio-boxed-metro.html">Boxed metro</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio
                                                    colorful<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-colorful-two-column.html">Colorful 2
                                                        column</a></li>
                                                    <li><a href="portfolio-colorful-three-column.html">Colorful 3
                                                        column</a></li>
                                                    <li><a href="portfolio-colorful-four-column.html">Colorful 4
                                                        column</a></li>
                                                    <li><a href="portfolio-colorful-masonry.html">Colorful masonry</a>
                                                    </li>
                                                    <li><a href="portfolio-colorful-metro.html">Colorful metro</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio
                                                    bordered<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-bordered-two-column.html">Bordered 2
                                                        column</a></li>
                                                    <li><a href="portfolio-bordered-three-column.html">Bordered 3
                                                        column</a></li>
                                                    <li><a href="portfolio-bordered-four-column.html">Bordered 4
                                                        column</a></li>
                                                    <li><a href="portfolio-bordered-masonry.html">Bordered masonry</a>
                                                    </li>
                                                    <li><a href="portfolio-bordered-metro.html">Bordered metro</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio
                                                    overlay<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-overlay-two-column.html">Overlay 2 column</a>
                                                    </li>
                                                    <li><a href="portfolio-overlay-three-column.html">Overlay 3
                                                        column</a></li>
                                                    <li><a href="portfolio-overlay-four-column.html">Overlay 4
                                                        column</a></li>
                                                    <li><a href="portfolio-overlay-masonry.html">Overlay masonry</a>
                                                    </li>
                                                    <li><a href="portfolio-overlay-metro.html">Overlay metro</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio switch
                                                    image<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-switch-image-two-column.html">Switch image 2
                                                        column</a></li>
                                                    <li><a href="portfolio-switch-image-three-column.html">Switch image
                                                        3 column</a></li>
                                                    <li><a href="portfolio-switch-image-four-column.html">Switch image 4
                                                        column</a></li>
                                                    <li><a href="portfolio-switch-image-masonry.html">Switch image
                                                        masonry</a></li>
                                                    <li><a href="portfolio-switch-image-metro.html">Switch image
                                                        metro</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Portfolio
                                                    other<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="portfolio-scattered.html">Portfolio scattered</a></li>
                                                    <li><a href="portfolio-justified-gallery.html">Justified gallery</a>
                                                    </li>
                                                    <li><a href="portfolio-slider.html">Portfolio slider</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Single project
                                                    page<i className="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="single-project-page-01.html">Single project page 01</a>
                                                    </li>
                                                    <li><a href="single-project-page-02.html">Single project page 02</a>
                                                    </li>
                                                    <li><a href="single-project-page-03.html">Single project page 03</a>
                                                    </li>
                                                    <li><a href="single-project-page-04.html">Single project page 04</a>
                                                    </li>
                                                    <li><a href="single-project-page-05.html">Single project page 05</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li> */}
                                    {/*<!-- <li class="nav-item dropdown megamenu">
                                        <a href="#" class="nav-link text-uppercase">Elements</a>
                                        <i class="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown" aria-hidden="true"></i>
                                        <div class="menu-back-div dropdown-menu megamenu-content" role="menu">
                                            <div class="d-lg-flex justify-content-center">
                                                <ul class="d-lg-inline-block">
                                                    <li class="dropdown-header">General</li>
                                                    <li><a href="accordions.html"><i class="ti-layout-accordion-separated"></i>Accordions</a></li>
                                                    <li><a href="buttons.html"><i class="ti-mouse"></i>Buttons</a></li>
                                                    <li><a href="team.html"><i class="ti-user"></i>Team</a></li>
                                                    <li><a href="team-carousel.html"><i class="ti-layout-slider-alt"></i>Team carousel</a></li>
                                                    <li><a href="clients.html"><i class="ti-id-badge"></i>Clients</a></li>
                                                    <li><a href="client-carousel.html"><i class="ti-layout-slider"></i>Client carousel</a></li>
                                                    <li><a href="subscribe.html"><i class="ti-crown"></i>Subscribe</a></li>
                                                    <li><a href="call-to-action.html"><i class="ti-eye"></i>Call to action</a></li>
                                                    <li><a href="tab.html"><i class="ti-layout-tab"></i>Tab</a></li>
                                                    <li><a href="google-map.html"><i class="ti-location-pin"></i>Google map</a></li>
                                                    <li><a href="contact-form.html"><i class="ti-clipboard"></i>Contact form</a></li>
                                                    <li><a href="image-gallery.html"><i class="ti-gallery"></i>Image gallery</a></li>
                                                </ul>
                                                <ul class="d-lg-inline-block">
                                                    <li class="dropdown-header">Content & infographics</li>
                                                    <li><a href="progress-bar.html"><i class="icon-hourglass"></i>Progress bar</a></li>
                                                    <li><a href="icon-with-text.html"><i class="ti-layout-media-left"></i>Icon with text</a></li>
                                                    <li><a href="overline-icon-box.html"><i class="ti-write"></i>Over line icon box</a></li>
                                                    <li><a href="custom-icon-with-text.html"><i class="ti-vector"></i>Custom icon with text</a></li>
                                                    <li><a href="counters.html"><i class="ti-timer"></i>Counters</a></li>
                                                    <li><a href="countdown.html"><i class="ti-alarm-clock"></i>Countdown</a></li>
                                                    <li><a href="pie-charts.html"><i class="ti-pie-chart"></i>Pie charts</a></li>
                                                    <li><a href="fancy-text-box.html"><i class="ti-layout-cta-center"></i>Fancy text box</a></li>
                                                    <li><a href="text-box.html"><i class="ti-layout-cta-left"></i>Text box</a></li>
                                                    <li><a href="fancy-text.html"><i class="ti-text"></i>Fancy text</a></li>
                                                </ul>
                                                <ul class="d-lg-inline-block">
                                                    <li class="dropdown-header">Interactive</li>
                                                    <li><a href="testimonials.html"><i class="ti-thought"></i>Testimonials</a></li>
                                                    <li><a href="testimonials-carousel.html"><i class="ti-comments"></i>Testimonials carousel</a></li>
                                                    <li><a href="video.html"><i class="ti-video-camera"></i>Video</a></li>
                                                    <li><a href="interactive-banners.html"><i class="ti-image"></i>Interactive banners</a></li>
                                                    <li><a href="services.html"><i class="ti-bookmark-alt"></i>Services</a></li>
                                                    <li><a href="info-banner.html"><i class="ti-layout-slider"></i>Info banner</a></li>
                                                    <li><a href="rotate-box.html"><i class="ti-package"></i>Rotate box</a></li>
                                                    <li><a href="process-step.html"><i class="ti-layers"></i>Process step</a></li>
                                                    <li><a href="instagram.html"><i class="ti-instagram"></i>Instagram</a></li>
                                                    <li><a href="parallax-scrolling.html"><i class="ti-exchange-vertical"></i>Parallax scrolling</a></li>
                                                    <li><a href="text-slider.html"><i class="ti-layout-media-overlay"></i>Text slider</a></li>
                                                </ul>
                                                <ul class="d-lg-inline-block">
                                                    <li class="dropdown-header">Text & containers</li>
                                                    <li><a href="heading.html"><i class="ti-text"></i>Heading</a></li>
                                                    <li><a href="dropcaps.html"><i class="ti-smallcap"></i>Drop caps</a></li>
                                                    <li><a href="columns.html"><i class="ti-layout"></i>Columns</a></li>
                                                    <li><a href="blockquote.html"><i class="ti-quote-left"></i>Blockquote</a></li>
                                                    <li><a href="highlights.html"><i class="ti-underline"></i>Highlights</a></li>
                                                    <li><a href="message-box.html"><i class="ti-layout-media-right-alt"></i>Message box</a></li>
                                                    <li><a href="social-icons.html"><i class="ti-user"></i>Social icons</a></li>
                                                    <li><a href="lists.html"><i class="ti-list"></i>Lists</a></li>
                                                    <li><a href="seperators.html"><i class="ti-layout-line-solid"></i>Separators</a></li>
                                                    <li><a href="pricing-table.html"><i class="ti-layout-column2"></i>Pricing table</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown simple-dropdown">
                                        <a href="#" class="nav-link text-uppercase">Features</a>
                                        <i class="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown" aria-hidden="true"></i>
                                        <ul class="dropdown-menu" role="menu">
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Header and menu<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="transparent-header.html">Transparent header</a></li>
                                                    <li><a href="white-header.html">White header</a></li>
                                                    <li><a href="dark-header.html">Dark header</a></li>
                                                    <li><a href="header-with-top-bar.html">Header with top bar</a></li>
                                                    <li><a href="header-with-push.html">Header with push</a></li>
                                                    <li><a href="center-navigation.html">Center navigation</a></li>
                                                    <li><a href="center-logo.html">Center logo</a></li>
                                                    <li><a href="top-logo.html">Top logo</a></li>
                                                    <li><a href="one-page-navigation.html">One page navigation</a></li>
                                                    <li class="dropdown">
                                                        <a data-bs-toggle="dropdown" href="#">Hamburger menu<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                        <ul class="dropdown-menu">
                                                            <li><a href="hamburger-menu.html">Hamburger menu</a></li>
                                                            <li><a href="hamburger-menu-modern.html">Hamburger menu modern</a></li>
                                                            <li><a href="hamburger-menu-half.html">Hamburger menu half</a></li>
                                                        </ul>
                                                    </li>
                                                    <li class="dropdown">
                                                        <a data-bs-toggle="dropdown" href="#">Left menu<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                        <ul class="dropdown-menu">
                                                            <li><a href="left-menu-classic.html">Left menu classic</a></li>
                                                            <li><a href="left-menu-modern.html">Left menu modern</a></li>
                                                        </ul>
                                                    </li>
                                                    <li class="dropdown">
                                                        <a data-bs-toggle="dropdown" href="#">Header type<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                        <ul class="dropdown-menu">
                                                            <li><a href="header-always-fixed.html">Always fixed</a></li>
                                                            <li><a href="header-disable-fixed.html">Disable fixed</a></li>
                                                            <li><a href="header-reverse-scroll.html">Reverse scroll</a></li>
                                                            <li><a href="header-responsive-sticky.html">Responsive sticky</a></li>
                                                        </ul>
                                                    </li>
                                                    <li class="dropdown">
                                                        <a data-bs-toggle="dropdown" href="#">Mobile menu<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                        <ul class="dropdown-menu">
                                                            <li><a href="mobile-menu-classic.html">Classic</a></li>
                                                            <li><a href="mobile-menu-modern.html">Modern</a></li>
                                                            <li><a href="mobile-menu-full-screen.html">Full screen</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Footer<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="footer-style-01.html">Footer style 01</a></li>
                                                    <li><a href="footer-style-02.html">Footer style 02</a></li>
                                                    <li><a href="footer-style-03.html">Footer style 03</a></li>
                                                    <li><a href="footer-style-04.html">Footer style 04</a></li>
                                                    <li><a href="footer-style-05.html">Footer style 05</a></li>
                                                    <li><a href="footer-style-06.html">Footer style 06</a></li>
                                                    <li><a href="footer-style-07.html">Footer style 07</a></li>
                                                    <li><a href="footer-style-08.html">Footer style 08</a></li>
                                                    <li><a href="footer-style-09.html">Footer style 09</a></li>
                                                    <li><a href="footer-style-10.html">Footer style 10</a></li>
                                                    <li><a href="footer-style-11.html">Footer style 11</a></li>
                                                    <li><a href="footer-style-12.html">Footer style 12</a></li>
                                                </ul>
                                            </li>
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Page title<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="page-title-left-alignment.html">Left alignment</a></li>
                                                    <li><a href="page-title-right-alignment.html">Right alignment</a></li>
                                                    <li><a href="page-title-center-alignment.html">Center alignment</a></li>
                                                    <li><a href="page-title-colorful-style.html">Colorful style</a></li>
                                                    <li><a href="page-title-big-typography.html">Big typography</a></li>
                                                    <li><a href="page-title-parallax-background.html">Parallax background</a></li>
                                                    <li><a href="page-title-separate-breadcrumbs.html">Separate breadcrumbs</a></li>
                                                    <li><a href="page-title-gallery-background.html">Gallery background</a></li>
                                                    <li><a href="page-title-background-video.html">Background video</a></li>
                                                    <li><a href="page-title-mini-version.html">Mini version</a></li>
                                                </ul>
                                            </li>
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Modal popup<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="modal-simple.html">Simple modal</a></li>
                                                    <li><a href="modal-subscription.html">Subscription</a></li>
                                                    <li><a href="modal-contact-form.html">Contact form</a></li>
                                                    <li><a href="modal-youtube-video.html">Youtube video</a></li>
                                                    <li><a href="modal-vimeo-video.html">Vimeo video</a></li>
                                                    <li><a href="modal-google-map.html">Google map</a></li>
                                                </ul>
                                            </li>
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Icon packs<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="icon-packs-icons-mind-line.html">Icons mind line</a></li>
                                                    <li><a href="icon-packs-icons-mind-solid.html">Icons mind solid</a></li>
                                                    <li><a href="icon-packs-feather.html">Feather</a></li>
                                                    <li><a href="icon-packs-font-awesome.html">Font awesome</a></li>
                                                    <li><a href="icon-packs-et-line.html">ET line</a></li>
                                                    <li><a href="icon-packs-themify.html">Themify</a></li>
                                                    <li><a href="icon-packs-simple-line.html">Simple line</a></li>
                                                </ul>
                                            </li>
                                            <li class="dropdown">
                                                <a href="animations.html">Animations</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown simple-dropdown">
                                        <a href="#" class="nav-link text-uppercase">Blog</a>
                                        <i class="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown" aria-hidden="true"></i>
                                        <ul class="dropdown-menu" role="menu">
                                            <li class="dropdown"><a href="blog-grid.html">Blog grid</a></li>
                                            <li class="dropdown"><a href="blog-masonry.html">Blog masonry</a></li>
                                            <li class="dropdown"><a href="blog-classic.html">Blog classic</a></li>
                                            <li class="dropdown"><a href="blog-simple.html">Blog simple</a></li>
                                            <li class="dropdown"><a href="blog-side-image.html">Blog side image</a></li>
                                            <li class="dropdown"><a href="blog-metro.html">Blog metro</a></li>
                                            <li class="dropdown"><a href="blog-overlay-image.html">Blog overlay image</a></li>
                                            <li class="dropdown"><a href="blog-modern.html">Blog modern</a></li>
                                            <li class="dropdown"><a href="blog-clean.html">Blog clean</a></li>
                                            <li class="dropdown"><a href="blog-widget.html">Blog widget</a></li>
                                            <li class="dropdown"><a href="blog-standard.html">Blog standard</a></li>
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Post layout<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="blog-post-layout-01.html">Blog post layout 01</a></li>
                                                    <li><a href="blog-post-layout-02.html">Blog post layout 02</a></li>
                                                    <li><a href="blog-post-layout-03.html">Blog post layout 03</a></li>
                                                    <li><a href="blog-post-layout-04.html">Blog post layout 04</a></li>
                                                    <li><a href="blog-post-layout-05.html">Blog post layout 05</a></li>
                                                </ul>
                                            </li>
                                            <li class="dropdown">
                                                <a data-bs-toggle="dropdown" href="#">Post types<i class="fas fa-angle-right dropdown-toggle"></i></a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="blog-standard-post.html">Standard post</a></li>
                                                    <li><a href="blog-gallery-post.html">Gallery post</a></li>
                                                    <li><a href="blog-slider-post.html">Slider post</a></li>
                                                    <li><a href="blog-html5-video-post.html">HTML5 video post</a></li>
                                                    <li><a href="blog-youtube-video-post.html">Youtube video post</a></li>
                                                    <li><a href="blog-vimeo-video-post.html">Vimeo video post</a></li>
                                                    <li><a href="blog-audio-post.html">Audio post</a></li>
                                                    <li><a href="blog-blockquote-post.html">Blockquote post</a></li>
                                                    <li><a href="blog-full-width-post.html">Full width post</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown megamenu">
                                        <a href="#" class="nav-link text-uppercase">Shop</a>
                                        <i class="fa fa-angle-down dropdown-toggle" data-bs-toggle="dropdown" aria-hidden="true"></i>
                                        <div class="menu-back-div dropdown-menu megamenu-content" role="menu">
                                            <div class="d-lg-flex justify-content-center">
                                                <ul class="d-lg-inline-block md-margin-15px-bottom">
                                                    <li class="dropdown-header">Shop layout</li>
                                                    <li><a href="shop-wide.html">Shop wide</a></li>
                                                    <li><a href="shop-left-sidebar.html">Left sidebar</a></li>
                                                    <li><a href="shop-right-sidebar.html">Right sidebar</a></li>
                                                    <li><a href="shop-only-categories.html">Only categories</a></li>
                                                    <li><a href="single-product.html">Single product</a></li>
                                                    <li class="dropdown-header">Utility pages</li>
                                                    <li><a href="shopping-cart.html">Shopping cart</a></li>
                                                    <li><a href="checkout.html">Checkout</a></li>
                                                    <li><a href="login-register.html">Login / Register</a></li>
                                                </ul>
                                                <ul class="d-lg-inline-block d-none small-gap">
                                                    <li>
                                                        <a href="index.html" class="menu-banner-image"><img src="/assets/images/menu-banner-01.jpg" alt=""/></a>
                                                    </li>
                                                </ul>
                                                <ul class="d-lg-inline-block d-none no-padding-right">
                                                    <li>
                                                        <a href="index.html" class="menu-banner-image"><img src="/assets/images/menu-banner-02.jpg" alt=""/></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li> -->*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto col-lg-2 text-end pe-0 font-size-0">
                            <div className="header-button d-none d-md-inline-block">
                                <a href="contact-us-classic.html"
                                   className="btn btn-very-small btn-black btn-round-edge box-shadow-large">log in</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
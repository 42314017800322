import React from 'react';

function Footer() {
    return (
        <>
            {/*// <!-- start scroll to top -->*/}
            <a className="scroll-top-arrow" href="javascript:void(0);"><i className="feather icon-feather-arrow-up"></i></a>
            {/*// <!-- end scroll to top -->*/}
        </>
    );
}

export default Footer;
import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify';

const P2pTrade = () => {
    const apiUrl = "https://extapi.btc6x.com/buy-process.php";

    const [BuyRequestItem, setBuyRequestItem] = useState({
        email:"",
        name:"",
        country:"",
        requiredNetwork:"",
        methodReceivingMethod:"",
        amount:"",
        stableCoin:"CCU",
        currency:"USD",
        note:"",
    })

    const [SellRequestItem, setSellRequestItem] = useState({
        email:"",
        name:"",
        country:"",
        requiredNetwork:"",
        methodReceivingMethod:"",
        amount:"",
        stableCoin:"CCU",
        currency:"USD",
        note:"",
    })


    const handleBuyRequest = () =>{
        if(BuyRequestItem.email.trim() === ""){
            toast.error("please enter email")
        }else if(BuyRequestItem.name.trim() === ""){
            toast.error("please enter name")
        }else if(BuyRequestItem.country.trim() === ""){
            toast.error("please enter country")
        }else if(BuyRequestItem.requiredNetwork.trim() === ""){
            toast.error("please enter network")
        }else if(BuyRequestItem.methodReceivingMethod.trim() === ""){
            toast.error("please enter Method for Receiving the Amount")
        }else if(BuyRequestItem.amount.trim() === ""){
            toast.error("please enter amount")
        }else if(BuyRequestItem.stableCoin.trim() === ""){
            toast.error("please enter stablecoin")
        }else if(BuyRequestItem.note.trim() === ""){
            toast.error("please enter note")
        }else{

            let dataList = {
                "subject" : "Buy Order",
                "body" : "Buy Order Body",
                'email' : BuyRequestItem.email.trim(),
                'name' : BuyRequestItem.name.trim(),
                'paymentMethod' : BuyRequestItem.methodReceivingMethod.trim(),
                'amount' : BuyRequestItem.amount.trim(),
                'country' : BuyRequestItem.country.trim(),
                'currency' : BuyRequestItem.stableCoin.trim(),
                'note' : BuyRequestItem.note.trim(),
            }


            axios.post(apiUrl , dataList).then(({data}) => {
                if(data){
                    toast.success("Ticket created successfully!")
                }
            })
        }
    }

    const handleSellRequest = () =>{
        if(SellRequestItem.email.trim() === ""){
            toast.error("please enter email")
        }else if(SellRequestItem.name.trim() === ""){
            toast.error("please enter name")
        }else if(SellRequestItem.country.trim() === ""){
            toast.error("please enter country")
        }else if(SellRequestItem.requiredNetwork.trim() === ""){
            toast.error("please enter network")
        }else if(SellRequestItem.methodReceivingMethod.trim() === ""){
            toast.error("please enter Method for Receiving the Amount")
        }else if(SellRequestItem.amount.trim() === ""){
            toast.error("please enter amount")
        }else if(SellRequestItem.stableCoin.trim() === ""){
            toast.error("please enter stablecoin")
        }else if(SellRequestItem.note.trim() === ""){
            toast.error("please enter note")
        }else{
            let dataList = {
                "subject" : "Sell Order",
                "body" : "Sell Order Body",
                'email' : SellRequestItem.email.trim(),
                'name' : SellRequestItem.name.trim(),
                'paymentMethod' : SellRequestItem.methodReceivingMethod.trim(),
                'amount' : SellRequestItem.amount.trim(),
                'country' : SellRequestItem.country.trim(),
                'currency' : SellRequestItem.stableCoin.trim(),
                'note' : SellRequestItem.note.trim(),
            }


            axios.post(apiUrl , dataList).then(({data}) => {
                if(data){
                    toast.success("Ticket created successfully!")
                }
            })
        }
    }


    return ( 
        <>
            {/*<!-- start hero section -->*/}
            <section className="p-0 parallax mobile-height" data-parallax-background-ratio="0.5" style={{backgroundImage: "url('/assets/images/banner.jpg')"}}>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-12 col-lg-5 col-md-6 col-sm-8 full-screen md-h-650px sm-h-450px d-flex flex-column justify-content-center wow animate__fadeIn">
                            {/*<!-- <span className="text-extra-medium alt-font font-weight-500 text-uppercase text-yellow d-block margin-35px-bottom xs-margin-15px-bottom">buying and selling</span> -->*/}
                            <h4 className="alt-font font-weight-800 text-white text-uppercase margin-5px-bottom letter-spacing-minus-2px sm-margin-20px-bottom xs-margin-15px-bottom">buying and selling</h4>
                            <h4 className="alt-font font-weight-800 text-yellow text-uppercase margin-5px-bottom letter-spacing-minus-2px sm-margin-20px-bottom xs-margin-15px-bottom">stable coin</h4>
                            <p className="text-extra-medium line-height-30px margin-3-rem-bottom lg-w-90 md-w-100 xs-margin-15px-bottom text-light">
                                {/* receive your purchase request directly<br/>from the exchange */}
                                With the help of a network of chosen users, BTC6X exchange has made it possible to buy and sell Stablecoins via bank payments to local and international accounts, or payments via other ecurrencies such as Perfect Money. 
                                <br/>To use this service, you need to create a BTC6X account.
                            </p>
                            <div className="d-flex">
                                <a href="#buy-request" className="btn btn-fancy btn-large btn-yellow-ochre section-link align-self-start text-dark font-weight-700 margin-10px-right">PURCHASE</a>
                                <a href="#sell-request" className="btn btn-fancy btn-large btn-white section-link align-self-start text-dark font-weight-700">SALE</a>
                            </div>
                        </div>
                    </div>
                    <div className="down-section text-center z-index-1 xs-bottom-30px d-none d-sm-inline-block"><a href="#buy-request" className="section-link"><i className="fas fa-arrow-down text-dark-charcoal bg-white box-shadow-extra-large w-45px h-45px line-height-46px border-radius-100"></i></a></div>
                </div>
            </section>
            {/*<!-- end hero section -->*/}
            {/*<!-- start section -->*/}
            <section id='buy-request' className="big-section bg-gradient-yellow">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center margin-seven-bottom">
                            <p className="alt-font text-dark margin-10px-bottom font-weight-500">If you are looking to purchase Stablecoins, submit your request by filling out this form.</p>
                            <h5 className="alt-font text-dark font-weight-800 text-uppercase">Purchase order review steps</h5>
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom sm-margin-6-rem-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-white"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-white"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-white"></span>1</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-dark font-weight-500">Filling out the form and specifying the wallet to receive the purchased Stablecoins.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom sm-margin-6-rem-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-white"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-white"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-white"></span>2</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-dark font-weight-500">Review of the request text and referring the case to the agents and chosen users by the support team of BTC6X</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-white"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-white"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-white"></span>3</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-dark font-weight-500">Answering requests via email and the ticketing system, including acceptance requirements, rates, and bank account details for the transfer of amount.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-white"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-white"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-white"></span>4</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-dark font-weight-500">Transferring the amount by the user and then sending the receipts via the ticketing system</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-white"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-white"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-white"></span>5</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-dark font-weight-500">Transferring Stablecoins to the wallet of the user in BTC6X exchange.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                    </div>
                </div>
                <div className="container w-60 lg-w-90">
                    <div className="row justify-content-center">
                        <div className="col-12 bg-white padding-four-all border-radius-10px box-shadow">
                            <form id="project-contact-form" novalidate="">
                                <div className="row row-cols-1 row-cols-md-2">
                                    <div className="col">
                                        <input className="medium-input bg-white margin-25px-bottom required" type="email" name="name" placeholder="Your Email" onChange={e => setBuyRequestItem({...BuyRequestItem,email: e.target.value})} />
                                            <input className="medium-input bg-white margin-25px-bottom required" type="text" name="email" placeholder="Your Name" onChange={e => setBuyRequestItem({...BuyRequestItem,name: e.target.value})} />
                                                <input className="medium-input bg-white margin-25px-bottom" type="text" name="phone" placeholder="Country" onChange={e => setBuyRequestItem({...BuyRequestItem,country: e.target.value})} />
                                                    {/* <input className="medium-input bg-white margin-25px-bottom required" type="text" name="email" placeholder="Stablecoin" onChange={e => setBuyRequestItem({...BuyRequestItem,stableCoin: e.target.value})} /> */}
                                                        <select name="contry" id="contry" className="small-input" onChange={e => setBuyRequestItem({...BuyRequestItem,stableCoin: e.target.value})}>
                                                            <option value="CCU">CCU</option>
                                                            <option value="USDT">USDT</option>
                                                            <option value="ETH">ETH</option>
                                                        </select>
                                                        <select name="contry" id="contry" className="small-input" onChange={e => setBuyRequestItem({...BuyRequestItem,currency: e.target.value})}>
                                                            <option value="USD">USD</option>
                                                            <option value="EURO">EURO</option>
                                                        </select>
                                    </div>
                                    <div className="col">
                                        <input className="medium-input bg-white margin-25px-bottom required" type="text" name="name" placeholder="Required Network" onChange={e => setBuyRequestItem({...BuyRequestItem,requiredNetwork: e.target.value})}/>
                                            <input className="medium-input bg-white margin-25px-bottom required" type="text" name="email" placeholder="Method for Receiving the Amount" onChange={e => setBuyRequestItem({...BuyRequestItem,methodReceivingMethod: e.target.value})} />
                                                <input className="medium-input bg-white margin-25px-bottom required" type="text" name="name" placeholder="Expected Amount" onChange={e => setBuyRequestItem({...BuyRequestItem,amount: e.target.value})} />
                                                    <textarea className="medium-textarea bg-white h-120px" rows="6" name="comment" placeholder="Additonal Notes" onChange={e => setBuyRequestItem({...BuyRequestItem,note: e.target.value})} ></textarea>
                                    </div>
                                </div>
                                <div className="submit-btn-dir">
                                    <button id="project-contact-us-button" className="btn btn-medium btn-fancy btn-yellow-ochre text-dark font-weight-600 mb-0 submit" type="submit" onClick={handleBuyRequest}>Submit</button>
                                </div>
                                <div className="form-results d-none"></div>
                            </form>
                        </div>
                        <div className="col-12">
                            <p className="text-dark font-weight-500 margin-5px-bottom margin-20px-top">Notes:</p>
                            <p className="text-dark font-weight-500 margin-5px-bottom">1. This process will be under full observation and arbitration by BTC6X. In case of any dispute, it will be resolved based on the arbitration by BTC6X.</p>
                            <p className="text-dark font-weight-500 margin-5px-bottom">2. In the stages of transferring proceeds to bank accounts, it is necessary that the bank account owner’s information matches with the BTC6X account’s information. </p>
                            <p className="text-dark font-weight-500 margin-5px-bottom">3. These services are active for certain Stablecoins and in certain countries. Both of these will be developed gradually.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- end section -->*/}
            {/*<!-- start section -->*/}
            <section id='sell-request' className="big-section bg-black">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center margin-seven-bottom">
                            <p className="alt-font text-light margin-10px-bottom font-weight-500">If you would like to participate in the direct sale of the supported Stablecoins in this program, please follow these steps.</p>
                            <h5 className="alt-font text-yellow font-weight-800 text-uppercase">Sale request review steps</h5>
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom sm-margin-6-rem-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-yellow"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-yellow"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-yellow"></span>1</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-light font-weight-500">Filling out the following form and specifying the method to receive proceeds of sale (fiat money or ecurrencies)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom sm-margin-6-rem-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-yellow"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-yellow"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-yellow"></span>2</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-light font-weight-500">Review of the request text and referring the case to the agents and chosen users by the support team of BTC6X</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-yellow"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-yellow"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-yellow"></span>3</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-light font-weight-500">Answering requests via email and the ticketing system, including acceptance requirements, rates, and method of transferring proceeds.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-yellow"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-yellow"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-yellow"></span>4</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-light font-weight-500">Depositing the amount in the user’s account and submitting withdraw request to the wallet provided to the agent in step 3</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-yellow"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-yellow"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-yellow"></span>5</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-light font-weight-500">Waiting to receive the proceeds and confirming the receipt in the ticketing system.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                        {/* <!-- start process steps item --> */}
                        <div className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 margin-60px-bottom">
                            <div className="process-step-item">
                                <span className="process-step-item-bfr bg-yellow"></span>
                                <div className="process-step-item-box">
                                    <div className="process-step-icon">
                                        <span className="process-step-icon-bfr bg-yellow"></span>
                                        <span className="process-step-number text-slate-blue alt-font font-weight-500"><span className="process-step-number-bfr bg-yellow"></span>6</span>
                                    </div>
                                    <div className="process-content last-paragraph-no-margin">
                                        <p className="text-light font-weight-500">Waiting to receive the proceeds and confirming the receipt in the ticketing system.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end process steps item --> */}
                    </div>
                </div>
                <div className="container w-60 lg-w-90">
                    <div className="row justify-content-center">
                        <div className="col-12 bg-white padding-four-all border-radius-10px box-shadow">
                            <form id="project-contact-form" novalidate="">
                                <div className="row row-cols-1 row-cols-md-2">
                                    <div className="col">
                                        <input className="medium-input bg-white margin-25px-bottom required" type="email" name="name" placeholder="Your Email" onChange={e => setSellRequestItem({...SellRequestItem,email: e.target.value})} />
                                            <input className="medium-input bg-white margin-25px-bottom required" type="text" name="email" placeholder="Your Name" onChange={e => setSellRequestItem({...SellRequestItem,name: e.target.value})} />
                                                <input className="medium-input bg-white margin-25px-bottom" type="text" name="phone" placeholder="Country" onChange={e => setSellRequestItem({...SellRequestItem,country: e.target.value})} />
                                                    {/* <input className="medium-input bg-white margin-25px-bottom required" type="text" name="email" placeholder="Stablecoin" onChange={e => setSellRequestItem({...SellRequestItem,stableCoin: e.target.value})} /> */}
                                                        <select name="contry" id="contry" className="small-input" onChange={e => setSellRequestItem({...SellRequestItem,stableCoin: e.target.value})}>
                                                            <option value="CCU">CCU</option>
                                                            <option value="USDT">USDT</option>
                                                            <option value="ETH">ETH</option>
                                                        </select>
                                                        <select name="contry" id="contry" className="small-input" onChange={e => setSellRequestItem({...SellRequestItem,currency: e.target.value})}>
                                                            <option value="USD">USD</option>
                                                            <option value="EURO">EURO</option>
                                                        </select>
                                    </div>
                                    <div className="col">
                                        <input className="medium-input bg-white margin-25px-bottom required" type="text" name="name" placeholder="Required Network"  onChange={e => setSellRequestItem({...SellRequestItem,requiredNetwork: e.target.value})} />
                                            <input className="medium-input bg-white margin-25px-bottom required" type="text" name="email" placeholder="Method for Receiving the Amount" onChange={e => setSellRequestItem({...SellRequestItem,methodReceivingMethod: e.target.value})} />
                                                <input className="medium-input bg-white margin-25px-bottom required" type="text" name="name" placeholder="Expected Amount" onChange={e => setSellRequestItem({...SellRequestItem,amount: e.target.value})} />
                                                    <textarea className="medium-textarea bg-white h-120px" rows="6" name="comment" placeholder="Additonal Notes" onChange={e => setSellRequestItem({...SellRequestItem,note: e.target.value})}></textarea>
                                    </div>
                                </div>
                                <div className="submit-btn-dir">
                                    <button id="project-contact-us-button" className="btn btn-medium btn-fancy btn-yellow-ochre text-dark font-weight-600 mb-0 submit" type="submit" onClick={handleSellRequest}>Submit</button>
                                </div>
                                <div className="form-results d-none"></div>
                            </form>
                        </div>
                        <div className="col-12">
                            <p className="text-light font-weight-500 margin-5px-bottom margin-20px-top">Notes:</p>
                            <p className="text-light font-weight-500 margin-5px-bottom">1. This process will be under full observation and arbitration by BTC6X. In case of any dispute, it will be resolved based on the arbitration by BTC6X.</p>
                            <p className="text-light font-weight-500 margin-5px-bottom">2. In the stages of transferring proceeds to bank accounts, it is necessary that the bank account owner’s information matches with the BTC6X account’s information.</p>
                            <p className="text-light font-weight-500 margin-5px-bottom"> 3. These services are active for certain Stablecoins and in certain countries. Both of these will be developed gradually.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end section -->*/}
        </>
    );
}
 
export default P2pTrade;